import React from 'react'

const CreateUsers = () => {
  return (
    <div>
      Aquí se crean los usuarios
    </div>
  )
}

export default CreateUsers
