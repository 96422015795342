import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBarUser from "../../Navs/NavBarUser";
import axios from "axios";
import { Container, Col, Row, Nav, Button, Modal, Form } from "react-bootstrap";
import "../../../css/estilos.css";
import "../../../css/styles.css";
import { DateRangePicker, Message, Placeholder } from "rsuite";
import moment from "moment";

const { beforeToday } = DateRangePicker;
const styles = { width: 200, display: "block", marginBottom: 5, margin: "6%" };
const stylesM = {background: "#000", padding: 20,  position: "relative"};

function MenuRigh() {
  const [reservation, setReservation] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [showMessaSuce, setShowMessaSucce] = useState(false);
  const [showMessaWarnin, setShowMessaWarnin] = useState(false);
  const { id_habitacion } = useParams();
  var email;
  var full_name;
  var tel_celular;

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    let startDate = moment(value[0]).format("MMDDYY");
    let endDate = moment(value[1]).format("MMDDYY");
    console.log(startDate, endDate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    email = formData.get("email");
    full_name = formData.get("full_name");
    tel_celular = formData.get("tel_celular");
    CreateReservation();
  };

  function CreateReservation() {
    let bedroom_id = parseInt(id_habitacion);
    const data = { bedroom_id, dateRange, email, full_name, tel_celular };

    axios
      .post("http://192.168.3.68:4000/create_reservation", data)
      .then((response) => {
        setReservation(response.data);
        console.log(reservation);
        setShowMessaSucce(true);
        setShowMessaWarnin(false);
      })
      .catch((error) => {
        console.error(error);
        setShowMessaSucce(false);
        setShowMessaWarnin(true);
      });
  }

  function MessageS() {
    return <>
    <div style={stylesM}>
      <Message full showIcon type="success">
        Reservación completada, en breves te llegará un mensaje a tu correo con la información de tu reservación
      </Message>
    </div>
  </>
  }

  function MessageW() {
    return (
      <>
        <div style={stylesM}>
          <Message full showIcon type="warning">
            ¡Ups! Hubo un error intententelo nuevamente 
          </Message>
        </div>
      </>
    );
  }

  return (
    <>
      {showMessaSuce && <MessageS/>}
      {showMessaWarnin && <MessageW/>}
      <Nav
        expand="md"
        sticky="right"
        className="flex-column bg-light menu_righ"
      >
        <Form onSubmit={handleSubmit} className="text-center">
          <Form.Group className="mb-4">
            <Form.Label className="form_fecha">Fecha*</Form.Label>
            <DateRangePicker
              disabledDate={beforeToday()}
              size="lg"
              style={styles}
              onChange={handleDateRangeChange}
              placement="bottomRight"
            />
          </Form.Group>

          <Form.Group className="mb-4 form_right">
            <Form.Label>Nombre Completo*</Form.Label>
            <Form.Control
              type="text"
              name="full_name"
              placeholder="Jesus Cruz"
            />
          </Form.Group>

          <Form.Group className="mb-4 form_right">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="ghweller@mail.com"
            />
          </Form.Group>

          <Form.Group className="mb-4 form_right">
            <Form.Label>Teléfono Celular*</Form.Label>
            <Form.Control
              type="text"
              name="tel_celular"
              placeholder="7773531419"
            />
          </Form.Group>

          <Form.Group className="mb-4 form_right">
            <Button type="submit">Reservar</Button>
          </Form.Group>
        </Form>
      </Nav>
    </>
  );
}

const Bedrooms = () => {
  const { id_habitacion } = useParams();
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      let habitacion_id = parseInt(id_habitacion);
      const data = { habitacion_id };
      axios
        .post("http://192.168.3.68:4000/bedroom_for_id", data)
        .then((response) => {
          setDatos(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 200);
  }, []);

  return (
    <>
      <NavBarUser />
      <MenuRigh />
      <div className="content_bed">
        {datos.map((i) => {
          return (
            <Container fluid key={i.id}>
              <Row>
                <Col md={9}></Col>
                <h1>{i.name}</h1>
                <Col md={3}></Col>
              </Row>
              <Row>
                <Col md={9}></Col>
                <img src={i.image} width={30} />
                <Col md={3}>
                  <p></p>
                </Col>
              </Row>
              <Row>
                <Col md={9}></Col>
                <p>{i.description}</p>
                <Col md={3}></Col>
              </Row>
            </Container>
          );
        })}
      </div>
    </>
  );
};

function ModalLogin() {
  const [sata, setData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <Button variant="light" onClick={() => setShow(true)}>
        Reservar
      </Button>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reservar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-4 form_right">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control type="text" placeholder="Pepe Toño" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4 form_right">
                  <Form.Label>Cellphone number*</Form.Label>
                  <Form.Control type="text" placeholder="7773531419" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button>Reservar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Bedrooms;
