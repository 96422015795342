import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { LockFill} from 'react-bootstrap-icons';
import "../../../css/formularios.css";
import axios from 'axios';
import LoadingSpineerCss from '../../Utilities/LoadingSpineerCss';

const LoginForm = () => {
    // const [email, setEmail ] = useState("");
    // const [password, setPassword ] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [dataI,  setDataI ] = useState([]);
    const [loading, setLoading ] = useState(false);
 
    const handleSubmitForm = (event) => {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData(event.target);
      const email = formData.get("email");
      const password = formData.get("password");
      if (email && password) {
        loginAdmin(email, password);
      }
    };

    function loginAdmin(email, password) {
      if (email !== "" && password !== "") {
        const data = { email, password };
        axios
          .post("http://192.168.3.68:4000/login_admins", data)
          .then((response) => {
            setDataI(response.data);
            setLoading(false);
            if (response.data.length === 0) {
              setShowAlert(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setShowAlert(true);
          });
      }
    }

    useEffect(() => {
      if (dataI.length > 0) {
        const id_rol = dataI[0].rol_id;
        if (id_rol === 1) {
          window.location.href = "http://192.168.3.68:3000/a/crear-usuarios";
        } else if (id_rol === 2) {
          window.location.href = "http://192.168.3.68:3000/m/reservaciones";
        }
      }
    }, [dataI]);


  return (
    <Container>
         {showAlert && <Alert variant="danger">Contraseña incorrecta</Alert>}
         
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <div className="text-center mb-4">
            <LockFill size={50} />
            <h1 className="h3 mb-3 font-weight-normal">Sign in</h1>
          </div>

          {loading ? <LoadingSpineerCss /> : (
            <Form onSubmit={handleSubmitForm}>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name='email' placeholder="Enter email" required />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name='password' placeholder="Password" required />
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>

            <Button variant="primary" type="submit" className="btn-block mt-3">
              Sign In
            </Button>
          </Form>
          ) }
          

          <div className="text-center mt-3">
            <a href="#">Forgot password?</a>
            <span className="mx-2">|</span>
            <a href="#">Don't have an account? Sign Up</a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

  
export default LoginForm
