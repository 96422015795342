import React, { useEffect, useState } from "react";
import NavBarUser from "../../Navs/NavBarUser";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRangePicker, Rate } from "rsuite";
import "../../../css/estilos.css";
import "../../../css/styles.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const styles = { width: 200, display: "block", marginBottom: 5, margin: 25 };

const ListBedrooms = (props) => {
  const { id_hotel } = useParams();
  const [info, setInfo] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      let hotel_id = parseInt(id_hotel);
      console.log(hotel_id);
      const data = { hotel_id };
      axios
        .post("http://192.168.3.68:4000/bedrooms_the_hotel", data)
        .then((response) => {
          setInfo(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error); // manejar los errores
        });
    }, 200);
  }, []);

  return (
    <>
      <NavBarUser />
      <MenuLeft />
      <div className="content_bedrooms">
        <h1>Habitaciones</h1>
        <div className="card float-right">
          <Row>
            {info.map((i) => {
              return (
                <div
                  key={i.id}
                  className="row"
                  onClick={() => {
                    window.location.href = `/habitacion/${i.id}`;
                  }}
                >
                  <Col sm={5}>
                    <img src={i.image} className="img_bedrooms d-block w-100" />
                  </Col>
                  <Col sm={7}>
                    <div className="card-block">
                      <h3>{i.name}</h3>
                      <p>{i.description}</p>
                      <Rate disabled defaultValue={4} />
                    </div>
                  </Col>
                </div>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

function handleRoomClick(params) {
  console.log("Haz presionado aquí" + params);
}

function MenuLeft() {
  const [value, setValue] = useState([20, 70]);

  function valuetext(value) {
    return `$${value}00.00`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Nav className="flex-column bg-light menu_left">
        <Nav.Item>
          <DateRangePicker size="lg" placeholder="Fecha" style={styles} />
        </Nav.Item>
        <Nav.Item>
          <h6 style={{margin: "6%"}}>Price</h6>
          <Box sx={{ width: 150, margin: "6%" }}>
            <Slider
              getAriaLabel={() => "Price range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
          </Box>
        </Nav.Item>
      </Nav>
    </>
  );
}

function ContainerBe() {
  return (
    <div>
      <h1>Gweller</h1>
      <p>Derechos Reservados</p>
    </div>
  );
}

export default ListBedrooms;
