import React from "react";
import "../../css/spinner.css"

function LoadingSpineerCss() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}

export default LoadingSpineerCss;
