import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo from "../../assets/logo_ghweller.png"

const NavBarUser = () => {
  return (
    <Navbar bg="light" expand="md" className="justify-content-between align-items-center" sticky="top">
    <Container fluid>
      <Navbar.Brand href="/">
        <img src={Logo} alt="Logo" width="250" height="60" className="d-inline-block align-top" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto ">
          <Nav.Link href="/">INICIO</Nav.Link>
          <Nav.Link href="#">HABITACIONES</Nav.Link>
          <Nav.Link href="/about">SERVICIOS</Nav.Link>
          <Nav.Link href="#link">DÓNDE IR</Nav.Link>
          <Nav.Link href="#">GALERÍA</Nav.Link>
          <Nav.Link href="#link">CONTÁCTO</Nav.Link>
          <Nav.Link href="/login-form">LOGIN</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
}

export default NavBarUser
