import React, { useEffect, useState } from "react";
import NavBarUser from "../../Navs/NavBarUser";
import "../../../css/estilos.css";
import { useNavigate } from "react-router-dom";
import {
  Carousel,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
} from "react-bootstrap";

const Home = () => {
  return (
    <>
      <NavBarUser />

      <Container fluid>
        <Carrusel1 />
        <Row>
          <h3>We Hotel Acapulco</h3>
        </Row>
        <Row>
          <h1>TU ESTANCIA SERÁ INOLVIDABLE</h1>
        </Row>
        <Row>
          <p>
            En We Hotel Acapulco, ubicado frente a Playa Hornos, sabemos lo
            importante que es su descanso, le aseguramos que nuestro personal
            altamente calificado hará que su estancia sea inolvidable.
          </p>
          <p>
            Nuestro hotel se encuentra localizado a tan solo 5 minutos del
            Fuerte de San Diego, a 2 cuadras del Parque Papagayo, a 10 minutos
            de la Quebrada, a 20 minutos de la Isla Roqueta y otros puntos de
            interés de esta gran ciudad.
          </p>
          <p>Sin duda la decisión más inteligente para hospedarse.</p>
        </Row>
        <Tarjetas />
        <Row>
          {/* <Button variant="light" onClick={ModalGetHotels}>Reservar</Button> */}
          <ModalGetHotels />
        </Row>
        <Row>
          <footer>
            <div>Este es el footer</div>
          </footer>
        </Row>
      </Container>
    </>
  );
};

function Reservar(id_hotel) {
  console.log("Usted va a ir a la vista de habitaciones" + id_hotel);
  window.location.href = `/habitaciones/${id_hotel}`;
}

function ModalGetHotels() {
  const [sata, setData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    fetch("http://192.168.3.68:4000/hotels")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log(sata);
      })
      .catch((e) => {
        console.error("Error: ", e);
      });
  }, []);

  return (
    <>
      <Button variant="light" onClick={() => setShow(true)}>
        Reservar
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hoteles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-group">
            {sata.map((i) => (
              <div key={i.id} className="col">
                <Card
                  style={{ width: "18rem" }}
                  className="d-flex card-equal-height"
                >
                  <Card.Img variant="top" src={i.image} />
                  <Card.Body>
                    <Card.Title>{i.name_hotel}</Card.Title>
                    <Card.Text>{i.description}</Card.Text>
                    <Button
                      onClick={() => {
                        window.location.href = `/habitaciones/${i.id}`;
                      }}
                      variant="primary"
                    >
                      Reservar
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Tarjetas = () => {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="mb-4">
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://wehotelacapulco.com.mx/images/services/rooms.jpg"
              />
              <Card.Body>
                <Card.Title>Habitaciones</Card.Title>
                <Card.Text>
                  Nuestras habitaciones están totalmente equipadas para hacer
                  que su instancia sea inolvidable.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://wehotelacapulco.com.mx/images/services/restaurant.jpg"
              />
              <Card.Body>
                <Card.Title>Lounge Bar</Card.Title>
                <Card.Text>
                  Conoce nuestro amplio catálogo de bebidas visitando nuestro
                  Lounge bar situado en la piscina.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://wehotelacapulco.com.mx/images/services/salon.jpg"
              />
              <Card.Body>
                <Card.Title>Spa</Card.Title>
                <Card.Text>
                  Ven y descubre nuestro SPA donde encontrarás un espacio para
                  conectarte contigo, próximamente en Acapulco.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const Carrusel1 = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://wehotelacapulco.com.mx/images/slider/slider-1.jpg"
            alt="First slide"
            //  height="675"
          />
          {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://wehotelacapulco.com.mx/images/slider/slider-2.jpg"
            alt="Second slide"
            //  height="675"
          />

          {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://wehotelacapulco.com.mx/images/slider/slider-3.jpg"
            alt="Third slide"
            //  height="675"
          />

          {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://wehotelacapulco.com.mx/images/slider/slider-4.jpg"
            alt="Third slide"
            //  height="675"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Home;
