import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Container } from "react-bootstrap";
import NavBarUser from "../../../Navs/NavBarUser";

const Dasboard = () => {
  const [dataD, setDataD] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get("http://192.168.3.68:4000/reservations")
        .then((response) => {
          setDataD(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 200);
  }, []);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = dataD.filter((item) =>
  Object.values(item).some(
      (value) =>
          value && value.toString().toLowerCase().includes(searchText.toLowerCase())
  )
);

  return (
    <>
      <NavBarUser />
      <Container fluid>
        <input
          type="text"
          placeholder="Buscar..."
          value={searchText}
          onChange={handleSearch}
        />
        <DataTable
          title="Reservaciones"
          columns={columns}
          data={filteredData}
          pagination="true"
          paginationRowsPerPageOptions={[15]}
          highlightOnHover="true"
          striped="true"
          responsive="true"
          fixedheader="false"
        />
      </Container>
    </>
  );
};

const columns = [
  {
    name: "Cliente",
    selector: (row) => row.full_name,
    sortable: true,
    reorder: true,
  },
  {
    name: "Contacto",
    selector: (row) => (
      <div className="text-center">
        Email: <br /> {row.email}
        <br />
        #Celular: <br />
        {row.tel_celular}
      </div>
    ),
    sortable: true,
    reorder: true,
  },
  {
    name: "Fecha Inicio",
    selector: (row) => row.start_day,
    sortable: true,
    reorder: true,
  },
  {
    name: "Fecha Fin",
    selector: (row) => row.end_day,
    sortable: true,
    reorder: true,
  },
  {
    name: "Habitación",
    selector: (row) => row.name,
    sortable: true,
    reorder: true,
  },
  {
    name: "Personas",
    selector: (row) =>
     (
      <div className="text-center">
         Mayores: <br/> {row.old_people} <br/>   Menores:  <br/>  {row.minor_people}
      </div>
     ),
    sortable: true,
    reorder: true,
  },
  {
    name: "Costo",
    selector: (row) => row.cost,
    sortable: true,
    reorder: true,
  },
  {
    name: "Status",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
];

export default Dasboard;
