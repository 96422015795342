import React from 'react'
import NavBarUser from '../../Navs/NavBarUser'

const About = () => {
  return (
    <>
    <NavBarUser />
    <div>
      Esto es para más imformación de about
    </div>
    </>
  )
}

export default About
