import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';

 import Home from './components/View/User/Home';
 import About from './components/View/User/About';
 import ListBedrooms from './components/View/hotel/ListBedrooms';
 import Bedrooms from './components/View/hotel/Bedrooms';
 import LoginForm from './components/View/forms/LoginForm';
 import Dasboard from './components/View/login/master/Dasboard';
 import CreateUsers from './components/View/login/admin/CreateUsers';

// const Home = lazy(() => import('./components/View/User/Home'));
 const Abaut = lazy(() => import('./components/View/User/About'));

const App = () => {
  //Falback se usa para mostrar miestras la aplicación carga
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/habitaciones/:id_hotel' element={<ListBedrooms />} />
        <Route path='/habitacion/:id_habitacion' element={<Bedrooms />} />
        <Route path='/login-form' element={<LoginForm />} />
        <Route path='/m/reservaciones' element={<Dasboard />} />
        <Route path='/a/crear-usuarios' element={<CreateUsers />} />
      </Routes>
    </Router>
    
  )
}

function AboutWithSuspense() {
  return (
    <Suspense fallback={<Spinner animation="border" />}>
      <Abaut />
    </Suspense>
  );
}

export default App
